@use '../../styles/constants.scss';

.home-page-container {
  max-width: 1366px;
  margin: auto;
}

.section-1-container {
  background: #fff6ef;
  display: flex;
  justify-content: center;
}

.section-1 {
  display: flex;
  column-gap: 3%;
  max-width: 1366px;
  max-height: 707px;
  flex-grow: 1;
  margin: 0 6.7%;
  padding-top: 40px;
  margin-bottom: 4%;
}

.hp-1-images-container {
  display: grid;
  grid-template-rows: auto auto; /* Creates two row tracks */
  grid-template-columns: 1fr;
  position: relative;
  width: 50.8%;
  height: 50.8%;
}

.hp-1-image-under {
  width: 83%;
  align-self: start;
  justify-self: start;
  margin-bottom: -62%;
  margin-right: -70%;
  z-index: 1;
}

.hp-1-image-over {
  width: 63%;
  border: 1.83vw solid white;
  box-sizing: content-box;
  z-index: 2;
  align-self: end;
  justify-self: end;
}

.hp-1-content-right {
  width: 45%;
  margin-top: 8vw;
}

.hp-1-welcome-text {
  font-family: 'Martel Sans', sans-serif;
  color: black;
  font-size: 1rem;
  line-height: 5px;
}

.hp-1-sp {
  font-family: 'Playfair Display', serif;
  color: black;
  font-weight: 700;
  font-size: 2.8rem;
}

.hp-1-description {
  margin-top: 20px;
  font-size: 1rem;
}

.hp-1-shop-button {
  /* width: 25.1%;
  padding: 2%; */
  margin-top: 20px;
  width: 133px;
  height: 48px;
  font-weight: 16px;
}

.sp-section-2-container {
  background: #ffffff;
  padding-top: min(10vw, 157px);
}

.sp-section-2 {
  display: flex;
  position: relative;
  padding-left: min(95px, 6.95%);
  margin: auto;
  max-width: 1366px;
  padding-bottom: 15%;
  overflow-x: hidden;
}

.sp-s2-content-left {
  display: flex;
  flex-direction: column;
  margin-top: min(21px, 1.4vw);
  width: 28%;
}

.sp-s2-title {
  font-family: 'Playfair Display', serif;
  color: black;
  font-weight: 700;
  font-size: 45px;
}

$image_slider_margin_right: -6%;

.sp-s2-content-right {
  right: -38px;
  // position: absolute;
  margin-left: auto;
  width: 69%;
  margin-right: $image_slider_margin_right;
  /* height: 392px; */
  /* flex-wrap: nowrap; */
}

.sp-s2-photo-slider {
  display: flex;
  justify-content: center;
  column-gap: 2%;
  overflow-x: hidden;
  position: relative;
}

.s2-img-slider-img {
  width: 32%;
  /* transform: translate(-106.25%); */
  flex-shrink: 0;
}

.s2-img-slider-img.from-right {
  animation-name: moveFromRight;
  animation-duration: 600ms;
  /* transform: translate(-106.25%); */
}

.s2-img-slider-img.from-left {
  animation-name: moveFromLeft;
  animation-duration: 600ms;
  /* transform: translate(-106.25%); */
}

.s2-img-slider-fake-img-div {
}

.s2-img-slider-img.final {
  /* display: none; */
}

.hp-s2-photo-arrows {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* width: 100%; */
  // position: absolute;
  margin-right: -2 * $image_slider_margin_right;
  margin-top: 2%;
  bottom: -75px;
  right: 75px;
  column-gap: 19px;
}

.sp-s2-description {
  margin-top: min(22px, 1.6vw);
}

.sp-s2-shop-all-button {
  margin-top: min(27px, 2.02vw);
  // width: fit-content;
  // padding: 4% 9%;
}

.section-3-container {
  background: #7f9296;
  // padding-bottom: 7%;
}

.section-3 {
  max-width: 1366px;
  display: flex;
  column-gap: 3%;
  margin: auto;
  height: 100%;
  padding: min(103px, 7.54vw) 6% min(103px, 7.54vw) 6%;
}

.s3-about-us-container {
  width: 46.7%;
  margin-top: min(59px, 4.42vw);
  margin-left: min(13px, 0.95vw);
}

.s3-about-us-title {
  font-family: 'Playfair Display', serif;
  color: #ffffff;
  font-weight: 700;
  font-size: 60px;
}

.s3-about-us-text {
  color: white;
  font-size: 14px;
  line-height: 18.48px;
  margin-top: min(29px, 2.12vw);
}

.s3-images-container {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;

  width: 47.58%;
}

.s3-image-behind {
  align-self: start;
  justify-self: end;
  margin-bottom: -61%;
  // margin-right: -70%;
  z-index: 1;
  width: 86.8%;
}

.s3-image-top {
  /* border: min(20px, 1.64vw) solid white; */
  border-color: white;
  border-style: solid;
  border-width: min(20px, 1.64vw);
  box-sizing: content-box;
  width: 66.14%;
  border-radius: 3px;
  align-self: end;
  justify-self: start;
  z-index: 2;
}

.section-4-container {
  background: #fff6ef;
  // max-height: 928px;
}

.section-4 {
  display: flex;
  max-width: 1366px;
  padding: 0 7%;
  height: 100%;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.s4-line {
  height: 9.15vw;
  max-height: 125px;
  width: 2px;
  background: #b1adad;
}

.s4-line-bottom {
  margin-top: auto;
}

.s4-title {
  font-family: 'Playfair Display', serif;
  color: black;
  font-weight: 700;
  font-size: 45px;
}

.s4-contact-us-container {
  margin-top: min(94px, 9.04vw);
  margin-bottom: 10%;
  display: flex;
}

.s4-cu-form {
  display: flex;
  flex-direction: column;
  width: 42%;
  flex-shrink: 0;
  flex-grow: 0;
}

.s4-cu-form-input {
  margin-bottom: min(62px, 4.54vw);
  background: none;
  border: none;
  border-bottom: 2px solid #b1adad;
  /* padding: 0px; */
  font-family: 'Martel Sans', sans-serif;
  color: black;
  opacity: 1;
  font-size: 1.375rem;
  line-height: 2.5rem;
}

.s4-cu-form-input::placeholder {
  font-family: 'Martel Sans', sans-serif;
  color: #b1adad;
  opacity: 1;
  font-size: 1.375rem;
  line-height: 2.5rem;
}

.s4-cu-form-input:focus {
  outline: none;
}

.s4-cu-right {
  margin-left: 14.7%;
  flex-shrink: 0;
  flex-grow: 0;
  width: 46.5%;
}

.s4-cu-subtitle {
  font-family: 'Spartan', sans-serif;
  color: black;
  font-weight: 700;
  font-size: 22px;
}

.s4-cu-text {
  margin-top: min(24px, 1.98vw);
}

.s4-cu-submit-button {
  // width: fit-content;
}

@media (min-width: 1366px) {
  .hp-1-image-over {
    top: 65px;
    border: 25px solid white;
  }
  .hp-1-content-right {
    margin-top: 150px;
  }
}

@media (min-width: 1473px) {
  .hp-s2-photo-arrows {
    bottom: -75px;
    right: 0px;
  }
}

@keyframes moveFromRight {
  from {
    transform: translateX(106.25%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes moveFromLeft {
  from {
    transform: translateX(-106.25%);
  }

  to {
    transform: translateX(0);
  }
}

@media (max-width: constants.$breakpoint-xl) {
}

@media (max-width: constants.$breakpoint-lg) {
}

@media (max-width: constants.$breakpoint-md) {
  .section-1 {
    margin: 0 0%;
    margin-bottom: 10%;
  }

  .hp-1-image-under {
    width: 83%;
    align-self: end;
    justify-self: start;
    margin-bottom: 0;
    margin-right: 0;
  }

  .hp-1-image-over {
    // top: 0;
    width: 83%;
    order: -1;
    align-self: start;
    justify-self: end;
    margin-bottom: -40%;
  }

  .sp-section-2 {
    flex-direction: column;
    padding-left: 0;
  }

  .sp-s2-content-left {
    width: 100%;
    align-items: center;
  }

  $image_slider_margin_left: -32%;

  .sp-s2-content-right {
    width: 100% + 2 * -$image_slider_margin_left;
    margin-left: $image_slider_margin_left;
    margin-top: 10%;
  }

  .hp-s2-photo-arrows {
    margin-right: -$image_slider_margin_left - 8%;
    column-gap: 10px;
  }

  .sp-s2-description {
    text-align: center;
    margin: 0 15%;
    margin-top: 3%;
    font-size: 1.2rem;
  }

  .section-3 {
    flex-direction: column;
    align-items: center;
    row-gap: 10%;
  }

  .s3-about-us-container {
    width: 90%;
  }

  .s3-images-container {
    width: 90%;
    padding-top: 4%;
  }

  .s3-image-behind {
    margin-bottom: -50%;
  }

  .s4-contact-us-container {
    flex-direction: column-reverse;
    row-gap: 4rem;
  }

  .s4-cu-form {
    width: 95%;
    align-items: center;
    row-gap: 0.5rem;
  }

  .s4-cu-form-input {
    font-size: 1rem;
    width: 100%;
  }

  .s4-cu-right {
    margin: 0 auto;
    width: 90%;
    font-size: 1.3rem;
    text-align: center;
  }

  .s4-cu-subtitle {
    display: none;
  }
}

@media (max-width: constants.$breakpoint-sm) {
  .hp-1-images-container {
    width: 42%;
  }

  .hp-1-content-right {
    left: 46%;
    width: 46%;
  }

  .hp-1-description {
    font-size: 1.4rem;
  }
}
