@use '../../styles/constants.scss';

.footer-container {
  background: #fff6ef;
}

.footer-content {
  margin: auto;
  display: flex;
  max-width: 1366px;
  /* padding-top: min(117px, 8.76vw); */
  padding: min(117px, 8.76vw) min(97px, 7.1vw) 3% min(97px, 7.1vw);
  overflow-x: hidden;
  // height: min(400px, 29.94vw);
}

.footer-content-left {
  width: 28.5%;
}

.footer-left-text {
  color: black;
  font-size: 12px;
  margin-top: min(13px, 0.97vw);
}

.footer-left-logos {
  margin-top: min(13px, 0.97vw);
  display: flex;
  column-gap: 7px;
}

.footer-left-logo:hover {
  cursor: pointer;
}

.footer-content-right {
  margin-top: 0.7vw;
  width: 58%;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-right-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: black;
  /* row-gap: 25px; */
  /* width: 33%; */
}

.footer-right-header {
  font-family: 'Playfair Display', serif;
  color: black;
  font-weight: 700;
  font-size: 1.875rem;
}

.footer-right-column-subtext {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.06rem;
  color: black;
}

.get-in-touch-row {
  display: flex;
  column-gap: 5px;
}

.get-in-touch-value {
  margin-top: auto;
  font-size: 1.06rem;
}

.footer-copyright {
  color: white;
  text-align: center;
  padding: 6px 0;
  font-size: 15px;
  background: #7f9296;
}

@media (max-width: constants.$breakpoint-xl) {
}

@media (max-width: constants.$breakpoint-lg) {
}

@media (max-width: constants.$breakpoint-md) {
  .footer-content {
    flex-direction: column;
  }

  .footer-content-left {
    width: 75%;
  }

  .footer-content-right {
    margin-left: 0;
    width: 100%;
  }

  .footer-right-git {
    flex-basis: 100%;
  }
}

@media (max-width: constants.$breakpoint-sm) {
}
