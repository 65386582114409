.rpp-container {
  min-height: 100vh;
  background: #fff6ef;
}

.rpp {
  max-width: 1366px;
  padding: 0 97px 0 97px;
  margin: 40px auto 0 auto;
  display: flex;
  align-items: flex-start;
}

.rpp-title {
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  margin-bottom: 48px;
  color: black;
  line-height: 40px;
}

.rpp-form {
  margin: auto 0 auto 10.6%;
  width: 36%;
}

.rpp-input-outer-container {
  position: relative;
}

.rpp-input-container {
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #b1adad;
  margin-top: 20px;
}

.rpp-label {
  display: flex;
}

.rpp-image {
  margin-left: auto;
  width: 45%;
}

.rppInput {
  margin-left: 25px;
  font-size: 18px;
  background: none;
  border: none;
  width: 100%;
}

.rppInput:focus {
  outline: none;
}

.rppInput::placeholder {
  color: #b1adad;
  opacity: 1;
}
