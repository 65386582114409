@use '../../styles/constants.scss';

.main-shop-container {
  min-height: 100vh;
  background: #fff6ef;
}

.main-shop {
  max-width: 1366px;
  padding: 0 7%;
  margin: 75px auto 0 auto;
  flex-grow: 1;
  min-height: 1100px;
}

.shop-lower {
  margin-top: 22px;
  display: flex;
}

.shop-content-left {
  width: 19.3%;
  /* margin-bottom: 50%; */
}

.shop-title {
  font-family: 'Playfair Display', serif;
  font-size: 2.8rem;
  line-height: 60px;
  color: black;
  text-transform: uppercase;
}

.shop-filter-header {
  /* display: flex; */
  /* align-items: center; */
  position: relative;
}

.shop-filter-title {
  font-family: 'Playfair Display', serif;
  font-size: 18px;
  color: black;
}

.shop-filter-svg-container {
  position: absolute;
  right: 0;
  width: 15%;
  top: 5%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 5%;
  user-select: none;
}

.shop-filter-svg-container:hover {
  cursor: pointer;
}

.shop-filter-container {
  position: sticky;
  top: 7vw;
  margin-top: 60px;
}

.shop-filter-list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.shop-filter-block {
  border-bottom: 2px solid #8d8a8a;
  padding: 33px 0;
}

.shop-filter-block:first-of-type {
  border-top: 2px solid #8d8a8a;
}

.shop-filter-block-title {
  font-size: 16px;
  color: black;
  font-weight: 600;
}

.shop-filter-block-filters {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: black;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.shop-filter-block-filter:hover:not(.active) {
  color: #656361;
  /* opacity: 0.6; */
}

.shop-filter-block-filters.hidden {
  display: none;
}

.shop-filter-block-filter.active {
  font-weight: 700;
}

.shop-filter-block-filter:hover {
  cursor: pointer;
}

.shop-price-slider-container {
  width: 90%;
}

.shop-twopointslider {
  margin-top: 10px;
  margin-bottom: 20px;
}

.shop-price-slider-prices {
  display: flex;
  margin-bottom: 7px;
}

.shop-price-filter-maxprice {
  margin-left: auto;
}

.content-right {
  column-gap: 27px;
  margin-left: auto;
  width: 75%;
  /* flex-wrap: wrap; */
}

.photo-photo-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 27px;
  row-gap: 70px;
}

.sp-photo-title-container:hover {
  cursor: pointer;
}

.photo-container {
  width: calc((100% - (27px * 2)) / 3);
}

.sp-photo-img-container {
  width: 100%;
  padding-bottom: 140%;
  overflow: hidden;
  position: relative;
}

.sp-photo-img {
  min-height: 100%;
  min-width: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
}

.shop-photo-bottom {
  margin-top: 19px;
  display: flex;
  align-items: center;
}

.sp-photo-title {
  margin-top: 18px;
  font-size: 24px;
  color: black;
}

.shop-add-to-cart {
  font-weight: 16px;
  width: 133px;
  height: 48px;
}

.shop-photo-price {
  margin-left: auto;
  color: black;
  font-size: 25px;
}

.sp-page-arrows {
  display: flex;
  align-items: center;
  margin-top: 70px;
  justify-content: flex-end;
  width: 100%;
  column-gap: 19px;
  margin-bottom: 30px;
}

.sp-arrow-left:hover,
.sp-arrow-right:hover {
  cursor: pointer;
}

.sp-arrow-right {
  /* transform: rotate(180deg); */
}

.no-filter-arrow-padding {
  margin-bottom: 90px;
}

@media (max-width: constants.$breakpoint-xl) {
}

@media (max-width: constants.$breakpoint-lg) {
}

@media (max-width: constants.$breakpoint-md) {
  .shop-lower {
    flex-direction: column;
  }

  .shop-content-left {
    width: 100%;
  }

  .shop-title {
    text-align: center;
    text-transform: none;
    font-size: 4rem;
  }

  .shop-title-br {
    display: none;
  }

  .main-shop,
  .shop-lower {
    margin-top: 0;
  }

  .main-shop {
    padding: 0;
  }

  .content-right {
    width: 90%;
    margin: 0 auto;
  }

  .photo-container {
    width: calc((100% - (27px)) / 2);
  }

  .shop-filter-list {
    margin-top: 0;
    flex-direction: row;
  }

  .shop-filter-container {
    margin-top: 15px;
  }

  .shop-filter-block {
    flex-grow: 1;
  }

  .shop-filter-block:first-of-type {
    border-top: none;
  }

  .shop-filter-block {
    border: none;
  }

  .shop-filter-list {
    border-top: 1px solid #b1adad;
    border-bottom: 1px solid #b1adad;
  }

  .shop-filter-block {
    padding: 14px 0;
  }

  .content-right {
    margin-top: 5.5%;
  }
}

@media (max-width: constants.$breakpoint-sm) {
}
