.shop-sort-by-contaner {
  position: relative;
}

.shop-sort-by-button {
  background: none;
  border: 1px solid #8d8a8a;
  font-size: 15px;
  margin-left: auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 46px;
  position: relative;
}

.shop-sort-by-button:hover {
  cursor: pointer;
}

.shop-sort-by-text {
  position: absolute;
  /* margin: auto 0; */
  left: 12px;
}

.shop-sort-by-arrow {
  position: absolute;
  right: 15px;
}

.sort-by-select {
  position: absolute;
  z-index: 5;
  top: 50px;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: white;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  user-select: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.shop-sort-select-row {
  display: flex;
  padding: 10px 0;
}

.shop-sort-select-row:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}

.shop-sort-select-row:not(:first-of-type) {
  border-top: 1px solid #d3d3d3;
}

.shop-sort-select-row-tick-container {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
