@use '../../styles/constants.scss';

$text_color: #000;

.navBar-container {
  background: #fff6ef;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.navBar-container.navBar-burger-menu-active {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 5;
}

.navBar {
  height: 93px;
  max-width: 1366px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  /* overflow: overlay; */
}

.navBar-mobile {
  display: none;
}

.navBar-burger-menu {
  row-gap: 1.6rem;
  display: flex;
  flex-direction: column;
  background-color: #fff6ef;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 7;
  padding: 5% 0 5% 5%;
  border-bottom-right-radius: 3%;
  border-bottom-left-radius: 3%;
}

.navBar-burger-item {
  font-family: 'Playfair Display', serif;
  font-size: 1.9rem;
}

.navBar-burger-menu-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 5;
}

.navBar-start-desktop {
  display: flex;
  position: absolute;
  font-family: 'Martel Sans';
  font-weight: 400;
  color: #b1adad;
  font-size: 16px;
  align-items: center;
  column-gap: 26px;
  left: 96px;
}

.navBar-start-mobile {
  position: absolute;
  left: 6%;
}

.navBar-end {
  display: flex;
  column-gap: 24px;
  // column-gap: 8%;
  // color: #b1adad;
  position: absolute;
  align-items: center;
  right: 7%;
}

.nav-user-container {
  color: #8d8a8a;
  font-size: 15px;
  position: relative;
}

.nav-user-container:hover {
  cursor: pointer;
}

.nav-user-container.active:hover {
  cursor: default;
}

.nav-user-arrow {
  margin-left: 10px;
}

.nav-user-modal {
  position: absolute;
  z-index: 1;
  width: 180px;
  border-radius: 3px;
  border: 1px solid #cfd6e1;
  background: white;
  /* box-shadow: 0px 2px 3px; */
}

.nav-user-arrow-path .active {
  fill: black;
}

.nav-user-modal-link {
  width: max-content;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
}

.nav-user-modal-link:hover {
  color: black;
  cursor: pointer;
}

.nav-user-modal-bottom-container {
  margin-top: 12px;
  border-top: 1px solid #cfd6e1;
}

.nav-user-modal-logout {
  padding-bottom: 15px;
}
.navBar-logo {
  /* margin: auto; */
  width: 135px;
}

.navBar-logo:hover {
  cursor: pointer;
}

.navBar-cart-icon:hover {
  cursor: pointer;
}

.navBar-button-container {
  color: #fff;
}

.navBar-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nabar-button:hover {
  cursor: pointer;
}

.navBar-signup {
  background: #7f9296;
}

.navBar-login {
  background: #ddb091;
}

.navBar-start-item-container {
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navBar-start-item-a.active {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
}

.navBar-start-item-a:not(.active):hover {
  /* opacity: 0.6; */
  color: #676460;
}

@media (max-width: constants.$breakpoint-xl) {
  .navBar-end {
    right: 4.5%;
    column-gap: 20px;
  }

  .navBar-start-desktop {
    left: 4.5%;
  }
}

@media (max-width: constants.$breakpoint-lg) {
  .navBar-logo {
    width: 115px;
  }

  .navBar-end {
    right: 3.5%;
    column-gap: 16px;
  }

  .navBar-start-desktop {
    left: 3.5%;
  }
}

@media (max-width: constants.$breakpoint-md) {
  .navBar-logo {
    width: 110px;
  }

  .navBar-mobile {
    display: block;
  }
}

@media (max-width: constants.$breakpoint-sm) {
}
