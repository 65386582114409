input[type='range'] {
  --minRangePercent: 0%;
  --maxRangePercent: 0%;
  height: 0.4rem;
  min-width: 21px;
  /* border-radius: 3px; */
}
input[type='range']:invalid {
  box-shadow: none;
}

input[type='range']::-webkit-slider-thumb {
}

.min-max-slider {
  position: relative;
  width: 200px;
  text-align: center;
}

.min-max-slider::focus {
  oultine: none;
}

input::focus {
  outline: none;
}

.min-max-slider > label {
  display: none;
}

.min-max-slider > input {
  cursor: pointer;
  position: absolute;
}

/* webkit specific styling */
.min-max-slider > input {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none !important;
  background: transparent;
}
.min-max-slider > input.min {
  background-image: linear-gradient(
    to right,
    rgb(241, 217, 200) 0%,
    rgb(241, 217, 200) var(--minRangePercent),
    #ddb091 var(--minRangePercent),
    #ddb091 100%
  );

  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.min-max-slider > input.max {
  background-image: linear-gradient(
    to right,
    #ddb091 0%,
    #ddb091 var(--maxRangePercent),
    rgb(241, 217, 200) var(--maxRangePercent),
    rgb(241, 217, 200) 100%
  );

  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.min-max-slider > input::-webkit-slider-runnable-track,
.min-max-slider > input::-moz-range-track,
.min-max-slider > input::-ms-track {
  box-sizing: border-box;
  border: none;
  height: 4px;
  background: #ddb091;
  border-radius: 8px;
  height: 10px;
  background-color: transparent;
  background-image: linear-gradient(#ddb091, #ddb091), linear-gradient(#ddb091, #ddb091);
  background-size: var(--sx) 10px, calc(100% - var(--sx)) 4px;
  background-position: left center, right center;
  background-repeat: no-repeat;
}

.min-max-slider > input::focus {
  outline: none;
}

.min-max-slider > input.max::-moz-range-progress {
  background: #ddb091;
  border-radius: 4px;
}

.min-max-slider > input.min::-moz-range-progress {
  background: rgb(241, 217, 200);
  border-radius: 4px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #ddb091; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border: none;
  color: 1px solid #ddb091;
  border-radius: 50%;
  /* box-shadow: -205px 0 0 200px hsl(100, 100%, 40%); */
}

input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #ddb091; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border: none;
  color: 1px solid #ddb091;
  border-radius: 50%;
  /* box-shadow: -205px 0 0 200px hsl(100, 100%, 40%); */
}

.min-max-slider > input::-webkit-slider-runnable-track {
  cursor: pointer;
}
