@use '../styles/constants.scss';

* {
  box-sizing: border-box;
}

html {
  width: 100vw;
  font-size: 16px;
}

body {
  overflow-x: hidden;
  font-family: 'Metropolis';
  color: black;
  margin: unset;
}

a:link,
a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

//Change grey-blue -> cadet
//change orange-brown -> orange

.sp-small-button {
  width: 130px;
  height: 48px;
}

.button-cadet,
.button-orange {
  border: none;
  color: #ffffff;
  border-radius: 4%;
  font-size: 1rem;
}

.button-cadet:visited,
.button-cadet:link,
.button-orange:visited,
.button-orange:link {
  color: #ffffff;
}

.button-cadet:hover,
.button-orange:hover {
  cursor: pointer;
}

.button-cadet {
  background: #7f9296;
}

.button-cadet:hover {
  background: #707f82;
}

.button-orange {
  background: #ddb091;
}

.button-orange:hover {
  background: #cfa588;
}

/* Removing arrows off number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form-error-inline-text {
  position: absolute;
  color: #ea001b;
  font-size: 0.9rem;
  margin-top: 0.5%;
  padding-left: 2%;
}

@media (max-width: constants.$breakpoint-xl) {
  html {
    font-size: 14px;
  }

  .sp-small-button {
    width: 127px;
    height: 47px;
  }
}

@media (max-width: constants.$breakpoint-lg) {
  html {
    font-size: 13px;
  }

  .sp-small-button {
    width: 116px;
    height: 43px;
  }
}

@media (max-width: constants.$breakpoint-md) {
  html {
    font-size: 12px;
  }

  .sp-small-button {
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}

@media (max-width: constants.$breakpoint-sm) {
  html {
    font-size: 11px;
  }
}
