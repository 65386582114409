$page_background_beige_color: #fff6ef;
$page_background_white_color: #ffffff;
$page_background_cadet_color: #7f9296;

$orange_color: #ddb091;
$cadet_color: #7f9296;

$add_button_color: #36c74c;
$edit_button_color: #3f6ad8;
$delete_button_color: #dc3232;

$text_color: #000;
$text_color_weak: #555f78;
$text_color_faded: #8e8e8e;
$text_color_contrast: #fff;

$table_row_alternate_color: #f7f7f7;

$input_background_color_default: #f6f6f5;

// Ensure these match styles in constants.js
// We define mobile as <=$breakpoint-md, desktop > $breakpoint-md
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
