@use '../../styles/constants.scss';

.lip-container {
  min-height: 100vh;
  background: #fff6ef;
}

.lip {
  max-width: 1366px;
  padding: 0 97px 0 97px;
  margin: 40px auto 0 auto;
  display: flex;
  align-items: flex-start;
}

.lip-input-outer-container {
  position: relative;
}

.lip-email-input-container {
  margin-top: 55px;
}

.lip-password-input-container {
  margin-top: 25px;
}

.lip-email-input-container,
.lip-password-input-container {
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #b1adad;
}

.lip-email-input-container.error,
.lip-password-input-container.error {
  border-color: #e86a6a;
}

.lip-password-input-container error .lip-password-input-container {
  margin-top: 20px;
}

#lipemailInput,
#lippasswordInput {
  margin-left: 25px;
  font-size: 18px;
  background: none;
  border: none;
  width: 100%;
}

#lipemailInput:focus,
#lippasswordInput:focus {
  outline: none;
}

#lipemailInput::placeholder,
#lippasswordInput::placeholder {
  color: #b1adad;
  opacity: 1;
}

.lip-label {
  display: flex;
}

.lip-image {
  width: 45%;
}

.lip-form {
  margin: auto 0 auto 11%;
  width: 36%;
  display: flex;
  flex-direction: column;
}

.lip-login-button {
  margin-top: 49px;
  width: 133px;
  height: 48px;
}

.lip-signup-link-container {
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: baseline;
}

.lip-signup-pretext {
  color: black;
  font-size: 14px;
  font-family: 'Martel Sans', sans-serif;
  margin-top: auto;
}

.lip-signup-link {
  color: black;
  font-size: 22px;
  font-family: 'Playfair Display', serif;
  padding-left: 5px;
}

.lip-title {
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  color: black;
  line-height: 40px;
}

.lip-signup-forgot-password {
  color: constants.$text_color_faded;
  margin-left: auto;
}
