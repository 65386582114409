.fpp-container {
  min-height: 100vh;
  background: #fff6ef;
}

.fpp {
  max-width: 1366px;
  padding: 0 97px 0 97px;
  margin: 40px auto 0 auto;
  display: flex;
  align-items: flex-start;
}

.fpp-email-input-outer-container {
  position: relative;
}

.fpp-email-input-container {
  margin-top: 30px;
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #b1adad;
}

.fpp-email-input-container.error {
  border-color: #e86a6a;
}

.fpp-image {
  width: 45%;
}

.fpp-subtext {
  margin-top: 10px;
}

.fpp-form {
  margin: auto 0 auto 11%;
  width: 36%;
}

.fpp-title {
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  color: black;
  line-height: 40px;
}

.fpp-label {
  display: flex;
}

#fppemailInput {
  margin-left: 25px;
  font-size: 18px;
  background: none;
  border: none;
  width: 100%;
}

#fppemailInput:focus {
  outline: none;
}

#fppemailInput::placeholder {
  color: #b1adad;
  opacity: 1;
}

.fpp-continue-button {
  margin-top: 49px;
  width: 133px;
  height: 48px;
}

.fpp-mail-success {
  margin: auto 0 auto 11%;
  width: 36%;
}

.fpp-mail-success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fpp-success-mail-icon {
  width: 15%;
}

.fpp-success-title {
  font-size: 1.7rem;
  font-weight: 800;
  margin-top: 5%;
}

.fpp-success-text {
  margin-top: 4%;
  text-align: center;
}
